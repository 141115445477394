<template>
  <ul class="listBox">
    <li v-for="item in info">
      <div class="circle">
        <h3 class="caseName">
          <span style="margin-right: 10px">{{ item.subjectName }}</span>
          {{ item.title }}
        </h3>

        <van-circle
          v-model:current-rate="item.forIndex.review.cur"
          :rate="item.forIndex.review.percent"
          :speed="100"
          layer-color="rgb(247,244,244)"
          color="#18a4e0"
          size="10vw"
          :text="`批阅${item.forIndex.review.min}/${item.forIndex.review.max}`"
        />
        <van-circle
          v-model:current-rate="item.forIndex.finished.cur"
          :rate="item.forIndex.finished.percent"
          :speed="200"
          color="rgb(112,181,3)"
          size="10vw"
          :text="`提交${item.forIndex.finished.min}/${item.forIndex.finished.max}`"
        />
      </div>
      <div class="fnBtn">
        <div>{{ item.gradeName }}{{ item.className }}...</div>
        <div style="margin-top: 10px">
          {{ item.forIndex.date.startTime }}至{{
            item.forIndex.date.blockingTime
          }}
        </div>
        <div class="preview" @click="gotoReview(item.id)">进入批阅</div>
      </div>
    </li>
    <li v-if="!info.length" class="nothing">
      <van-empty description="没有待批阅的学案" />
    </li>
  </ul>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, defineProps } from "vue";

defineProps(["info"]);
const router = useRouter();

let currentRate1 = ref(0);
let currentRate2 = ref(0);
let currentRate3 = ref(0);

const gotoReview = (id) => {
  router.push({ path: "/teacher/fastReview", query: { case_id: id } });
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";

:deep(.van-circle__text) {
  font-size: 9px;
}
.listBox {
  li {
    .bs();
    margin-bottom: 10px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;

    .fnBtn {
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .preview {
        padding: 3px 5px;
        border-radius: 3px;
        color: #fff;
        margin-top: 15px;
        background-color: rgb(112, 181, 3);
      }
    }
  }
  .nothing {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

:deep(.van-circle) {
  margin-right: 20px;
}

.caseName {
  span {
    padding: 2px 5px;
    border-radius: 3px;
    background-color: rgb(226, 245, 196);
    color: rgb(112, 181, 3);
  }
}

:deep(.van-circle__text) {
  font-size: 10px;
}

// 横屏
@media screen and (orientation: landscape) {
}

// 手机竖屏
@media screen and (orientation: portrait) and (max-device-width: 420px) {
  :deep(.van-circle__text) {
    font-size: 7px;
  }

  .listBox {
    li {
      background-color: #fff;
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .fnBtn {
        display: flex;
        flex-direction: column;
        align-items: center;

        .preview {
          padding: 3px 5px;
          border-radius: 3px;
          color: #fff;
          margin-top: 15px;
          background-color: rgb(112, 181, 3);
        }
      }
    }
  }
}
</style>
