<template>
  <div class="quick-nav">
    <van-grid :column-num="column">
      <van-grid-item
        v-for="(item, i) in list"
        :key="i"
        :icon="item.icon"
        :text="item.text"
        :badge="item.badge"
        :icon-color="item.iconColor"
        @click="handleItem(i)"
      />
    </van-grid>

    <van-action-sheet
      :round="false"
      v-model="showAction"
      :actions="actionList"
      cancel-text="取消"
      close-on-click-action
      @select="onActionSelect"
      @cancel="showAction = false"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { Toast } from "vant";
import { useRouter } from "vue-router";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    column: {
      type: Number,
      default: 4,
    },
  },
  emits: ["callback"],
  setup(props, ctx) {
    const router = useRouter();

    const state = reactive({
      showAction: false,
      actionList: [
        {
          name: "text",
        },
      ],
    });

    const handleItem = (i) => {
      const item = props.list[i];

      let type = "";
      if (typeof item.routeName != "undefined" && item.routeName) {
        type = "route";
      }
      if (typeof item.actionSheet != "undefined" && item.actionSheet) {
        type = "actionSheet";
      }
      if (typeof item.isClock != "undefined" && item.isClock) {
        type = "clock";
      }
      if (typeof item.callback != "undefined" && item.callback) {
        type = "callback";
      }

      switch (type) {
        case "route":
          toRoute(item.routeName);
          break;
        case "actionSheet":
          state.showAction = true;
          state.actionList = item.actionSheet;
          break;
        case "clock":
          break;
        case "callback":
          item.changeIndex = i;
          ctx.emit("callback", item);
          break;
        default:
          Toast.fail("功能未开放！");
          break;
      }
    };

    const onActionSelect = (item) => {
      toRoute(item.routeName);
    };

    const toRoute = (routeName) => {
      router.push({ name: routeName });
    };

    return {
      ...toRefs(state),
      handleItem,
      onActionSelect,
    };
  },
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";

:deep(.van-badge__wrapper) {
  color: #fff;
  background-color: #18a4e0;
  padding: 5px;
  border-radius: 50%;
}

:deep(.van-grid-item__text) {
  color: #18a4e0;
  font-weight: 700;
}

.quick-nav {
  overflow: hidden;
  border-radius: 8px;
  background-color: @fc;
  .bs();
}

/* 竖屏 */
@media screen and (orientation: portrait) {
}

/* 横屏 */
@media screen and (orientation: landscape) {
}
</style>
<style>
.quick-nav .van-grid-item__text {
  font-size: 8px !important;
}
.quick-nav .van-grid-item__content {
  padding: 8px;
}
</style>
