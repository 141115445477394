<template>
  <div class="index">
    <s-header />
    <n-bar :userType="'teacher'" :activeItemName="'index'" />
    <support />

    <div class="pageContent">
      <div class="content">
        <div class="iconBox">
          <van-image
            width="30px"
            height="30px"
            :src="require('@/assets/images/data.png')"
          />
          <span>今日学案数据</span>
        </div>

        <div class="data">
          <van-grid :gutter="10" :column-num="4">
            <van-grid-item v-for="item in indexData.today">
              <div class="statisticsData">{{ item.text }}{{ item.unit }}</div>
              <div class="statisticsTitle">{{ item.name }}</div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>

      <div class="content">
        <div class="iconBox">
          <van-image
            width="30px"
            height="30px"
            :src="require('@/assets/images/dataTotal.png')"
          />
          <span>累计学案数据</span>
        </div>

        <div class="data dataTotal">
          <van-grid :gutter="10" :column-num="4">
            <van-grid-item v-for="item in indexData.cumulative">
              <div class="statisticsData">{{ item.text }}{{ item.unit }}</div>
              <div class="statisticsTitle">{{ item.name }}</div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>

      <div class="qNav">
        <qNav :list="quickNavList" :column="3"></qNav>
      </div>

      <div class="content">
        <div class="mode">
          <div class="iconBox">
            <van-image
              width="30px"
              height="30px"
              :src="require('@/assets/images/u565.png')"
            />
            <span>待批阅学案习题</span>
          </div>
          <div style="color: #18a4e0" @click="modeCase">更多></div>
        </div>

        <div class="data list">
          <van-list
            v-model:loading="caseLoading"
            :finished="caseLoadingFinished"
            finished-text="没有更多了"
            @load="getCaseList"
          >
            <IndexList :info="caseInfo"></IndexList>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import IndexList from "./components/IndexList.vue";
import qNav from "@/components/QuickNav";
import { apiGetCasesList, apiStatistIndex } from "@/service/teacher.js";

const router = useRouter();

let indexData = ref({});

const getIndexData = async () => {
  let res = await apiStatistIndex();
  if (res) {
    indexData.value = res.data;
  }
};
getIndexData();

// 快速导航数组
const quickNavList = [
  {
    icon: "description",
    text: "学案",
    badge: "",
    routeName: "teacherCaseList",
  },
  // {
  //   icon: require("@/assets/images/u1314.png"),
  //   text: "错题集",
  //   badge: "",
  //   routeName: "",
  // },
  {
    icon: "send-gift-o",
    text: "资料库",
    badge: "",
    routeName: "",
  },
  {
    icon: require("@/assets/images/u685.png"),
    text: "学习报告",
    badge: "",
    routeName: "",
  },
];

// 学案数据
let caseInfo = ref([]);

// 列表loading效果
let caseLoading = ref(true);
// 结束loading效果
let caseLoadingFinished = ref(false);

let page = 0;
let limit = 10;
let awaitReview = ref([]);
const getCaseList = async () => {
  page++;
  let res = await apiGetCasesList({
    page: page,
    limit: limit,
    searchTitle: "",
    isToday: "",
    appointedDate: "",
    subjectId: "",
    bookId: "",
    chapterTreeId: "",
    isReview: 2,
  });
  if (res) {
    caseLoading.value = false;
    res.data.forEach((item, index) => {
      // 选中状态
      item.check = false;
      item.forIndex.review.cur = 0;
      item.forIndex.finished.cur = 0;
      if (item.finishedTotal && !item.is_review && caseInfo.value.length < 4) {
        awaitReview.value.push(item);
      }
    });

    caseInfo.value = caseInfo.value.concat(awaitReview.value);

    if (res.data.length < limit) {
      caseLoadingFinished.value = true;
    }
  } else {
    caseLoading.value = false;
    caseLoadingFinished.value = true;
  }
};

const modeCase = () => {
  router.push({ name: "teacherCaseList", query: { mode: 1 } });
};
</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";

.iconBox {
  padding: 10px;
  display: flex;
  align-items: center;
  font-weight: bold;
  span {
    font-size: 12px;
    margin-left: 10px;
  }
}

.statisticsData {
  color: #18a4e0;
  font-size: 13px;
  font-weight: 700;
}

.statisticsTitle {
  color: #666;
  font-size: 10px;
}

.data {
  .info {
    width: 50px;
    height: 100px;

    background-color: rgb(102, 204, 255);
  }
}

.data {
  :deep(.van-grid-item__content) {
    background-color: #fff;
    .bs();
  }
  :deep(.van-grid-item__content) {
    box-shadow: 0.05333rem 0.05333rem 0.10667rem #eee !important;
    border: none;
    border-radius: 6px;
  }
}

@media screen and (orientation: landscape) {
}

.dataTotal {
  margin-bottom: 10px;
}

.qNav {
  padding: 0 8px;
  padding-top: 40px;
}

.list {
  padding: 10px;
}

.qNav {
  padding: 20px 10px;
}

@media screen and (orientation: portrait) and (max-device-width: 420px) {
  :deep(.van-list__finished-text) {
    margin-bottom: 50px;
  }
  // .qNav {
  //   padding: 30px 10px;
  // }
}

.mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  font-weight: bold;
  font-size: 12px;
}
</style>
